import { render, staticRenderFns } from "./AnketQuestionText.vue?vue&type=template&id=1e495dee&scoped=true&"
import script from "./AnketQuestionText.vue?vue&type=script&lang=js&"
export * from "./AnketQuestionText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e495dee",
  null
  
)

export default component.exports