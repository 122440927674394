<template>
	<div class="question">
		<p class="txt">{{label}}</p>
		<div class="ans check">
			<div class="ansItem" @click="value = !value">
				<span class="t">Так</span>
				<div class="checkDeco" :class="{active: value === true}">
					<span class="ic-bx_bx-check"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AnketCheckBox",
	props: {
		label: {
			type: String,
			default: "placeholder",
		},
		showError: {
			type: Boolean,
			default: true,
		},
		propname: {
			type: [String, Object],
			default: "",
		},
		// prevalue: {
		// 	type: Boolean,
		// 	default: null,
		// },
		validate: {
			type: Object,
		},
	},
	data() {
		return {
			value: null,
		};
	},
	watch: {
		value(newValue) {
			this.$emit("change", { name: this.propname, value: newValue });
		},
	},
	mounted() {
		// if (this.prevalue) {
		// 	this.value = this.prevalue;
		// }
	},
};
</script>

<style lang="scss" scoped>
</style>