<template>
	<div class="answer">
		<div class="ttl">{{label}}</div>
		<textarea placeholder="Уведіть вручну " v-model.trim="value"></textarea>
	</div>
</template>

<script>
export default {
	name: "AnketQuestionCheck",
	props: {
		label: {
			type: String,
			default: "placeholder",
		},
		showError: {
			type: Boolean,
			default: true,
		},
		propname: {
			type: [String, Object],
			default: "",
		},
		prevalue: {
			type: Boolean,
			default: null,
		},
		validate: {
			type: Object,
		},
	},
	data() {
		return {
			value: null,
		};
	},
	watch: {
		value(newValue) {
			this.$emit("change", { name: this.propname, value: newValue });
		},
	},
	mounted() {
		if (this.prevalue) {
			this.value = this.prevalue;
		}
	},
};
</script>

<style lang="scss" scoped>
</style>