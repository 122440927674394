<template>
	<div class="anketMainWrp">
		<div class="col"></div>
		<main v-if="state == 'anket'">
			<div class="container articleWrp">
				<img
					:src="require('@/assets/img/Plug.svg')"
					alt=""
					class="top_illustration"
				/>
				<h1>
					☺️ Будь гарнюнею - заповни анкету донора вдома! <br />
					Це заощадить до 30 хвилин перебування в плазмацентрі
				</h1>
				<faq-component :compdata="moca" v-if="false"> </faq-component>
				<div class="AnketForm">
					<template>
						<div class="formTitle">ЗАГАЛЬНИЙ СТАН ЗДОРОВ'Я</div>
						<anket-question
							:label="q.q1_0"
							:showError="showError"
							propname="q1_0"
							:validate="$v.q1_0"
							@change="onFieldChange"
							:gender="true"
							:prevalue="q1_0"
						></anket-question>
						<anket-question
							:label="q.q1_1"
							:showError="showError"
							propname="q1_1"
							:validate="$v.q1_1"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q1_2"
							:showError="showError"
							propname="q1_2"
							:validate="$v.q1_2"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q1_3"
							:showError="showError"
							propname="q1_3"
							:validate="$v.q1_3"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q1_4"
							:showError="showError"
							propname="q1_4"
							:validate="$v.q1_4"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q1_5"
							:showError="showError"
							propname="q1_5"
							:validate="$v.q1_5"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q1_6"
							:showError="showError"
							propname="q1_6"
							:validate="$v.q1_6"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q1_7"
							:showError="showError"
							propname="q1_7"
							:validate="$v.q1_7"
							@change="onFieldChange"
						></anket-question>
					</template>

					<template>
						<div class="formTitle">ПРОТЯГОМ ОСТАННЬОГО РОКУ</div>
						<anket-question
							:label="q.q2_1"
							:showError="showError"
							propname="q2_1"
							:validate="$v.q2_1"
							@change="onFieldChange"
						></anket-question>
					</template>

					<template>
						<div class="formTitle">ПРОТЯГОМ ОСТАННІХ 6 МІСЯЦІВ</div>
						<anket-question
							:label="q.q3_1"
							:showError="showError"
							propname="q3_1"
							:validate="$v.q3_1"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q3_2"
							:showError="showError"
							propname="q3_2"
							:validate="$v.q3_2"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q3_3"
							:showError="showError"
							propname="q3_3"
							:validate="$v.q3_3"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q3_4"
							:showError="showError"
							propname="q3_4"
							:validate="$v.q3_4"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q3_5"
							:showError="showError"
							propname="q3_5"
							:validate="$v.q3_5"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q3_6"
							:showError="showError"
							propname="q3_6"
							:validate="$v.q3_6"
							@change="onFieldChange"
						></anket-question>
					</template>

					<template>
						<div class="formTitle">ПРОТЯГОМ ОСТАННІХ 2-Х ТИЖНІВ</div>
						<anket-question
							:label="q.q4_1"
							:showError="showError"
							propname="q4_1"
							:validate="$v.q4_1"
							@change="onFieldChange"
						></anket-question>
					</template>

					<template>
						<div class="formTitle">ЧИ СПОСТЕРІГАЄТЬСЯ У ВАС</div>
						<anket-question
							:label="q.q5_1"
							:showError="showError"
							propname="q5_1"
							:validate="$v.q5_1"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q5_2"
							:showError="showError"
							propname="q5_2"
							:validate="$v.q5_2"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q5_3"
							:showError="showError"
							propname="q5_3"
							:validate="$v.q5_3"
							@change="onFieldChange"
						></anket-question>
						<anket-question
							:label="q.q5_4"
							:showError="showError"
							propname="q5_4"
							:validate="$v.q5_4"
							@change="onFieldChange"
						></anket-question>
						<anket-question-text
							v-if="q5_4"
							:label="q.q5_4t"
							:showError="showError"
							propname="q5_4t"
							:validate="$v.q5_4t"
							@change="onFieldChange"
						></anket-question-text>
						<anket-question
							:label="q.q5_5"
							:showError="showError"
							propname="q5_5"
							:validate="$v.q5_5"
							@change="onFieldChange"
						></anket-question>
						<date-picker-field
							class="answer"
							v-if="q5_5"
							:label="q.q5_4d"
							:placeholder="'Вкажiть дату'"
							:showError="showError"
							propname="q5_4d"
							:validate="$v.q5_4d"
							@change="onFieldChange"
						></date-picker-field>
						<anket-question
							:label="q.q5_6"
							:showError="showError"
							propname="q5_6"
							:validate="$v.q5_6"
							@change="onFieldChange"
						></anket-question>
						<date-picker-field
							class="answer"
							v-if="q5_6"
							:label="q.q5_6d"
							:placeholder="'Вкажiть дату'"
							:showError="showError"
							propname="q5_6d"
							:validate="$v.q5_6d"
							@change="onFieldChange"
						></date-picker-field>
						<anket-question-text
							v-if="q5_6"
							:label="q.q5_6t"
							:showError="showError"
							propname="q5_6t"
							:validate="$v.q5_6t"
							@change="onFieldChange"
						></anket-question-text>
						<anket-question
							:label="q.q5_7"
							:showError="showError"
							propname="q5_7"
							:validate="$v.q5_7"
							@change="onFieldChange"
						></anket-question>
						<anket-question-text
							v-if="q5_7"
							:label="q.q5_7t"
							:showError="showError"
							propname="q5_7t"
							:validate="$v.q5_7t"
							@change="onFieldChange"
						></anket-question-text>
					</template>

					<template v-if="!q1_0">
						<div class="formTitle">ДОДАТКОВО ДЛЯ ЖІНОК</div>
						<anket-question
							:label="q.q6_1"
							:showError="showError"
							propname="q6_1"
							:validate="$v.q6_1"
							@change="onFieldChange"
						></anket-question>
						<anket-question-text
							:label="q.q6_2t"
							:showError="showError"
							propname="q6_2t"
							:validate="$v.q6_2t"
							@change="onFieldChange"
						></anket-question-text>
					</template>

					<template>
						<div class="formTitle"></div>
						<anket-check-box
							:label="q.q7_1"
							:showError="showError"
							propname="q7_1"
							:validate="$v.q7_1"
							@change="onFieldChange"
						></anket-check-box>
						<anket-check-box
							:label="q.q7_2"
							:showError="showError"
							propname="q7_2"
							:validate="$v.q7_2"
							@change="onFieldChange"
						></anket-check-box>
						<anket-check-box
							:label="q.q7_3"
							:showError="showError"
							propname="q7_3"
							:validate="$v.q7_3"
							@change="onFieldChange"
						></anket-check-box>

						<anket-question
							:label="q.q7_4"
							:showError="showError"
							propname="q7_4"
							:validate="$v.q7_4"
							@change="onFieldChange"
							:prevalue="q7_4"
						></anket-question>
						<anket-question
							:label="q.q7_5"
							:showError="showError"
							propname="q7_5"
							:validate="$v.q7_5"
							@change="onFieldChange"
							:prevalue="q7_5"
						></anket-question>
						<anket-question
							:label="q.q7_6"
							:showError="showError"
							propname="q7_6"
							:validate="$v.q7_6"
							@change="onFieldChange"
							:prevalue="q7_6"
						></anket-question>
					</template>

					<div class="submitWrp">
						<div class="errorTip" v-if="showError && $v.$invalid">
							Ви не вiдповiли на всi питання
						</div>
						<button
							class="submit"
							:class="{ disabled: isProcess }"
							@click="submit"
						>
							Відправити
						</button>
					</div>
				</div>
			</div>
		</main>
		<div class="formSuccess" v-else>
			<div class="closeWrp"></div>
			<div class="content">
				<div class="container">
					<img
						class="icn"
						:src="require(`@/assets/img/Group 874.svg`)"
						alt="123"
					/>
					<div class="title">{{ $t("form.formSuccess.title") }}</div>
					<div class="sub">{{ $t("form.formSuccess.sub") }}</div>
					<div class="controls">
						<router-link
							:to="{
								name: 'home',
								params: { locale: $i18n.locale == 'uk' ? null : $i18n.locale },
							}"
							class="toHome"
							>{{ $t("form.formSuccess.toTitle") }}</router-link
						>
						<router-link
							:to="{
								name: 'article',
								params: {
									locale: $i18n.locale == 'uk' ? null : $i18n.locale,
									slug: 'yak-pidgotuvatis-do-donaciji-plazmi-krovi',
								},
							}"
							class="toPage"
							>{{ $t("form.formSuccess.howToDonation") }}</router-link
						>
					</div>
				</div>
			</div>
			<div class="closeWrp">
				<span class="ic-close-rounded" @click="closeForm"></span>
			</div>
		</div>
		<div class="col closeWrp">
			<span class="ic-close-rounded" @click="closeForm"></span>
		</div>
	</div>
</template>

<script>
import AnketQuestion from "../components/common/AnketControls/AnketQuestion.vue";
import faqComponent from "../components/constructor/faqComponent.vue";
import { required } from "vuelidate/lib/validators";
import AnketQuestionText from "../components/common/AnketControls/AnketQuestionText.vue";
import AnketCheckBox from "../components/common/AnketControls/AnketCheckBox.vue";
const MustBeTrue = (value) => {
	return value === true;
};
import { helpers } from "vuelidate/lib/validators";
import DatePickerField from "../components/common/inputs/DatePickerField.vue";
const CheckTo = (param) =>
	helpers.withParams({ type: "CheckTo", value: param }, (value, vm) => {
		if (vm[param]) {
			if (value) {
				return true;
			}
			return false;
		}
		return true;
	});
export default {
	components: {
		faqComponent,
		AnketQuestion,
		AnketQuestionText,
		AnketCheckBox,
		DatePickerField,
	},
	name: "AnletaLayout",
	data() {
		return {
			showError: false,
			isProcess: false,
			moca: {
				list: [
					{
						title: "Як підготуватись до донації?",
						text: "<p>Як підготуватись до донації?</p>",
					},
				],
			},
			state: "anket",

			q: {},

			q1_0: true,
			q1_1: null,
			q1_2: null,
			q1_3: null,
			q1_4: null,
			q1_5: null,
			q1_6: null,
			q1_7: null,

			q2_1: null,

			q3_1: null,
			q3_2: null,
			q3_3: null,
			q3_4: null,
			q3_5: null,
			q3_6: null,

			q4_1: null,

			q5_1: null,
			q5_2: null,
			q5_3: null,
			q5_4: null,
			q5_4t: null,
			q5_5: null,
			q5_4d: null,
			q5_6: null,
			q5_6d: null,
			q5_6t: null,
			q5_7: null,
			q5_7t: null,

			q6_1: null,
			q6_2t: null,

			q7_1: null,
			q7_2: null,
			q7_3: null,
			q7_4: true,
			q7_5: null,
			q7_6: true,
		};
	},
	watch: {
		q1_0(val) {
			if (val == true) {
				this.q6_1 = null;
				this.q6_2t = null;
			}
		},
	},
	mounted() {
		let q = {};
		q.q1_0 = "Стать";
		q.q1_1 = "1. Як себе почуваєте? Добре?";
		q.q1_2 =
			"2. Чи є у Вас зараз температура, біль в горлі, застуда, респіраторні захворювання, ангіна?";
		q.q1_3 = "3. Чи вживали Ви протягом останніх 4-х годин їжу?";
		q.q1_4 = "4. Чи вживали Ви протягом останніх 48-ми годин алкоголь?";
		q.q1_5 =
			"5. Чи відвідували Ви стоматолога або зубного гігієніста напередодні?";
		q.q1_6 = "6. Чи приймали Ви протягом останнього місяця ліки?";
		q.q1_7 = "7. Чи перебуваєте Ви зараз під наглядом лікаря?";
		q.q2_1 = "Чи проводилися Вам щеплення?";
		q.q3_1 = "1. Чи проколювали Вам вуха?";
		q.q3_2 = "2. Чи робили Вам акупунктуру (лікування голковколюванням)?";
		q.q3_3 = "3. Чи робили Вам татуювання, пірсинг?";
		q.q3_4 =
			"4. Чи отримували Ви трансфузії компонентів крові або препаратів крові?";
		q.q3_5 = "5. Чи були у Вас хірургічні втручання?";
		q.q3_6 =
			"6. Чи були Ви у контакті з хворим на ВІЛ-інфекції/СНІД, гепатити B і C, інші венеричні хвороби?";
		q.q4_1 = "Чи робили Вам ін`єкції ліків?";
		q.q5_1 = "1. Нез`ясована втрата маси тіла?";
		q.q5_2 = "2. Нез`ясована нічна пітливість?";
		q.q5_3 = "3. Запаморочення, випадки непритомності?";
		q.q5_4 = "4. Чи були у Вас поїздки за кордон протягом останніх 3-х років?";
		q.q5_4t = "Назвіть країну";
		q.q5_5 = "5. Чи здавали Ви кров або її компоненти?";
		q.q5_4d = "Вкажіть дату останнього здавання крові або її компонентів";
		q.q5_6 = "6. Чи були відсторонення від донорства?";
		q.q5_6d = "Вкажіть дату відсторонення";
		q.q5_6t = "Вкажіть причину відсторонення";
		q.q5_7 = "7. Чи перебували Ви на диспансерному обліку?";
		q.q5_7t = "Якщо так, то в якому диспансері, поліклініці?";
		q.q6_1 =
			"1. Чи вагітні Ви зараз та чи була вагітність протягом останніх 6 тижнів?";
		q.q6_2t = "2. Термін останньої менструації?";
		q.q7_1 =
			"Я правильно відповів/ла на всі запитання Анкети і повністю усвідомив/ла значимість цієї інформації для мого здоров`я та здоров`я хворого/ї, якому/ій будуть введені мої компоненти крові або препарати моєї плазми крові.";
		q.q7_2 =
			"Я усвідомлюю те, що при приховуванні відомостей про наявність у мене ВІЛ-інфекції або венеричного захворювання (сифіліс, гонорея тощо)  підлягаю відповідальності згідно з чинним законодавством.";
		q.q7_3 =
			"Я не займаюсь наданням сексуальних послуг за плату, не вступаю у статеві  стосунки з малознайомими особами без презерватива, не схильний(а) до частої зміни статевих партнерів, хочу бути донором добровільно, мене ніхто не примушував.";
		q.q7_4 = "Чи погоджуєтесь ви отримати результати аналізів на телефон?";

		q.q7_5 = "Чи погоджуєтесь ви отримати компенсацію на банківську карту?";
		q.q7_6 =
			'"Я погоджуюсь з умовами політики конфіденційності і дозволяю обробляти мої персональні дані на законних засадах, а також повідомлений про обробку персональних даних, відповідно до ст. 12 Закону України «Про захист персональних даних»"';

		this.q = q;
	},
	validations() {
		return {
			q1_1: { required },
			q1_2: { required },
			q1_3: { required },
			q1_4: { required },
			q1_5: { required },
			q1_6: { required },
			q1_7: { required },
			q2_1: { required },
			q3_1: { required },
			q3_2: { required },
			q3_3: { required },
			q3_4: { required },
			q3_5: { required },
			q3_6: { required },
			q4_1: { required },
			q5_1: { required },
			q5_2: { required },
			q5_3: { required },
			q5_4: { required },
			q5_4t: { CheckTo: CheckTo("q5_4") },
			q5_5: { required },
			q5_4d: { CheckTo: CheckTo("q5_5") },
			q5_6: { required },
			q5_6d: { CheckTo: CheckTo("q5_6") },
			q5_6t: { CheckTo: CheckTo("q5_6") },
			q5_7: { required },
			q5_7t: { CheckTo: CheckTo("q5_7") },
			q6_1: {
				menstruationRequire: (val, vm) => {
					if (vm.q1_0 == false) {
						if (val != null) return true;
						else return false;
					} else return true;
				},
			},
			q6_2t: {
				menstruationRequire: (val, vm) => {
					if (vm.q1_0 == false) {
						if (val != null) return true;
						else return false;
					} else return true;
				},
			},
			q7_1: { MustBeTrue },
			q7_2: { MustBeTrue },
			q7_3: { MustBeTrue },
			q7_4: { required },
			q7_5: { required },
			q7_6: { required },
		};
	},
	methods: {
		closeForm() {
			this.$router.go(-1);
		},
		submit() {
			this.showError = true;
			if (!this.$v.$invalid && !this.isProcess) {
				this.isProcess = true;
				let questions = [];
				for (const key in this.q) {
					if (Object.hasOwnProperty.call(this.q, key)) {
						questions.push({
							id: key,
							question: this.q[key],
							answer: this[key],
						});
					}
				}
				let id = this.$route.query.id || "";
				let result = {
					id: id,
					questions,
				};
				console.log(JSON.stringify(result));

				this.axios
					.post("/api/questionnaire/send", {
						id: id,
						json: JSON.stringify(result),
					})
					.then((response) => {
						// console.log(result);
						console.log(response);
						this.isProcess = false;
						this.state = "success";
					})
					.catch((e) => {
						alert("error while sending");
						console.warn(e);
						this.isProcess = false;
					});
			}
		},
		onFieldChange(e) {
			this[e.name] = e.value;
		},
	},
};
</script>

<style lang="scss">
@import "../scss/article.scss";
.anketMainWrp {
	background-color: #f4a700;
	display: grid;
	grid-template-columns: 56px 1fr 56px;
	@media (max-width: 960px) {
		grid-template-columns: 1fr;
	}
	.col {
		@media (max-width: 960px) {
			display: none;
		}
	}
	.errorTip {
		color: rgb(243, 81, 81);
	}
	main {
		background-color: #fff;
		.container {
			padding: 0 15px 0;
			max-width: 560px;
			margin: 0 auto;
		}
		.articleWrp {
			padding-top: 48px;
			padding-bottom: 64px;
			.top_illustration {
				max-width: 303px;
				width: 100%;
				margin: 0 auto;
				display: block;
			}
			h1 {
				font-style: normal;
				font-weight: 600;
				font-size: 30px;
				line-height: 120%;
				letter-spacing: -0.02em;
				color: #000000;
			}
			.faqComponent {
				margin-left: 0;
				margin-right: 0;
			}
			.AnketForm {
				.formTitle {
					font-style: normal;
					font-weight: 600;
					font-size: 30px;
					line-height: 100%;
					letter-spacing: -0.02em;
					color: #000000;
					margin-bottom: 24px;
					margin-top: 96px;
					@media (max-width: 960px) {
						font-size: 23px;
					}
				}
				.question {
					display: grid;
					grid-template-columns: 1fr 180px;
					padding: 16px 0;
					box-shadow: inset 0px 1px 0px #8e8e93;
					@media (max-width: 960px) {
						grid-template-columns: 1fr 95px;
					}
					&:nth-child(odd) {
						background-color: #f2f2f2;
					}
					.txt {
						margin: 0;
						font-style: normal;
						font-weight: 600;
						font-size: 17px;
						line-height: 140%;
						letter-spacing: -0.01em;
						color: #000000;
					}
					.ans {
						display: grid;
						grid-template-columns: 1fr 1fr;
						&.check {
							grid-template-columns: 1fr;
						}
						.ansItem {
							display: flex;
							justify-content: center;
							align-items: center;
							flex-direction: column;
							cursor: pointer;
							.t {
								font-style: normal;
								font-weight: 600;
								font-size: 14px;
								line-height: 140%;
								text-align: center;
								color: #000000;
								text-align: center;
								display: block;
							}
							.checkDeco {
								width: 32px;
								height: 32px;
								border: 1px solid #8e8e93;
								box-sizing: border-box;
								display: block;
								margin-top: 10px;
								position: relative;
								transition: 0.3s;
								overflow: hidden;
								.ic-bx_bx-check {
									font-size: 24px;
									transition: 0.3s;
									border-radius: 50%;
									position: absolute;
									overflow: hidden;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									width: 0;
									height: 0;
									display: flex;
									justify-content: center;
									align-items: center;
									background-color: #f4a700;
								}
								&.active {
									border: 1px solid #f4a700;
									.ic-bx_bx-check {
										width: 150%;
										height: 150%;
									}
								}
							}
						}
					}
				}
				.answer {
					box-shadow: inset 0px 1px 0px #8e8e93;
					padding: 16px 0;
					.ttl {
						font-style: normal;
						font-weight: 600;
						font-size: 17px;
						line-height: 176.5%;
						color: #000000;
						margin-bottom: 8px;
					}
					textarea {
						border: none;
						box-shadow: 0 0 0 1px inset #8e8e93;
						box-sizing: border-box;
						border-radius: 4px;
						width: 100%;
						height: 100px;
						resize: none;
						outline: none;
						padding: 16px;
						transition: 0.3s ease;
						&:focus {
							box-shadow: 0 0 0 2px inset #f4a700;
						}
						&::placeholder {
							color: #aeaeb2;
						}
					}
				}
				.submitWrp {
					box-shadow: inset 0px 1px 0px #8e8e93;
					padding-top: 96px;
					margin-top: 16px;
					button {
						padding: 19px;
						background: #f4a700;
						border: none;
						display: block;
						width: 100%;
						cursor: pointer;
						&.disabled {
							opacity: 0.8;
							cursor: wait;
						}
					}
				}
			}
		}
	}
}
.formSuccess {
	display: grid;
	grid-template-columns: 56px 1fr 56px;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: #f4a700;
	@media (max-width: 1200px) {
		grid-template-columns: 1fr;
	}
	.closeWrp {
		padding-top: 6px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		@media (max-width: 1200px) {
			display: none;
		}
		.ic-close-rounded {
			width: 46px;
			height: 46px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
	.content {
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		@media (max-width: 1200px) {
			padding: 0 15px;
		}
		.container {
			width: calc(100vw - 30px);
			max-width: 560px;
			.icn {
				margin: 0 auto;
				display: block;
				width: 72px;
			}
			.title {
				font-style: normal;
				font-weight: 600;
				font-size: 32px;
				line-height: 100%;
				text-align: center;
				color: #f4a700;
				margin-top: 32px;
				@media (max-width: 1200px) {
					font-size: 38px;
				}
			}
			.sub {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 130%;
				text-align: center;
				color: #000000;
				margin-top: 16px;
			}
			.tabl {
				margin-top: 32px;
				border: 1px solid #8e8e93;
				box-sizing: border-box;
				* + .cell {
					border-top: 1px solid #8e8e93;
				}
				.cell {
					display: grid;
					grid-template-columns: 25% 1fr;
					grid-gap: 32px;
					padding: 8px;
					.caption {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 130%;
						color: #8e8e93;
					}
					.t1 {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 150%;
						color: #000000;
					}
					.t2 {
						font-style: normal;
						font-weight: 600;
						font-size: 30px;
						line-height: 135%;
						color: rgba(0, 0, 0, 0.96);
					}
				}
				.mapsLink {
					margin: 8px;
					display: block;
					border: 2px solid #f4a700;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					padding: 16px;
					text-decoration: none;
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 130%;
					color: #000000;
					.ic-marker {
						font-size: 26px;
						color: #f4a700;
						margin-right: 14px;
					}
				}
			}
			.controls {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 16px;
				margin-top: 32px;
				@media (max-width: 1200px) {
					grid-template-columns: 1fr;
				}
				.toHome,
				.toPage {
					height: 59px;
					line-height: 59px;
					font-style: normal;
					font-weight: 600;
					font-size: 13px;
					text-align: center;
					color: #000000;
					text-decoration: none;
				}
				.toHome {
					background: #e5e5ea;
				}
				.toPage {
					background: #f4a700;
				}
			}
		}
	}
}
.closeWrp {
	padding-top: 6px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	@media (max-width: 960px) {
		order: -1;
		padding-top: 0;
		align-items: center;
		justify-content: space-between;
		padding-left: 32px;
	}
	.ic-close-rounded {
		width: 46px;
		height: 46px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.mobStepInfo {
		display: none;
		@media (max-width: 960px) {
			display: flex;
			align-items: center;
		}
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 100%;
		text-align: center;
		color: #000000;
	}
}
</style>