<template>
	<div class="question">
		<p class="txt">{{label}}</p>
		<div class="ans">
			<div class="ansItem" @click="value = true">
				<span class="t">{{gender? 'Ч' : 'Так'}}</span>
				<div class="checkDeco" :class="{active: value === true}">
					<span class="ic-bx_bx-check"></span>
				</div>
			</div>
			<div class="ansItem" @click="value = false">
				<span class="t">{{gender? 'Ж' : 'Ні'}}</span>
				<div class="checkDeco" :class="{active: value === false}">
					<span class="ic-bx_bx-check"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AnketQuestion",
	props: {
		label: {
			type: String,
			default: "placeholder",
		},
		showError: {
			type: Boolean,
			default: true,
		},
		propname: {
			type: [String, Object],
			default: "",
		},
		prevalue: {
			type: Boolean,
			default: null,
		},
		validate: {
			type: Object,
		},
		gender:{
			type: Boolean,
			default: null,
		}
	},
	data() {
		return {
			value: null,
		};
	},
	watch: {
		value(newValue) {
			this.$emit("change", { name: this.propname, value: newValue });
		},
	},
	mounted() {
		if (this.prevalue) {
			this.value = this.prevalue;
		}
	},
};
</script>

<style lang="scss" scoped>
</style>