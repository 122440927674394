<template>
	<div>
		<div class="ttl">{{ label }}</div>
		<label :for="`input${_uid}`" class="dateComponent">
			<the-mask
				:id="`input${_uid}`"
				:placeholder="placeholder"
				class="date"
				:mask="masks"
				v-model.trim="value"
				:masked="true"
				@click.native="onFocus"
			/>
			<span class="ic-calendar"></span>
			<date-select
				v-if="dateShown"
				class="date_select"
				:focusDate="focusDate"
				@clicked="onCli"
				:initialDate="initialDate"
				@select="onDpChange"
			></date-select>
		</label>
		<div class="tip" v-if="showError && validate.$invalid">
			{{ $t("fieldErrors.selectDate") }}
		</div>
	</div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import DateSelect from "./calendar/DateSelect.vue";
export default {
	components: { DateSelect, TheMask },
	name: "DatePicker",
	props: {
		placeholder: {
			type: String,
			default: "placeholder",
		},
		label: {
			type: String,
			default: "label text",
		},
		showError: {
			type: Boolean,
			default: true,
		},
		propname: {
			type: [String, Object],
			default: "",
		},
		prevalue: {
			type: [Date, String],
			default: null,
		},
		validate: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			dateShown: false,
			value: "",
			initialDate: null,
			focusDate: new Date(),
		};
	},
	watch: {
		value(newValue) {
			let s = newValue.split(".");
			if (s && s.length == 3 && s[2].length == 4) {
				let dateStr = `${s[1]}.${s[0]}.${s[2]}`;
				let d = new Date(dateStr);
				if (Object.prototype.toString.call(d) === "[object Date]") {
					if (isNaN(d.getTime())) {
						this.onChange(null);
					} else {
						this.initialDate = d;
						this.focusDate = d;
						this.onChange(d);
					}
				}
			} else {
				this.onChange(null);
			}
		},
	},
	computed: {
		masks() {
			return ["##.##.####"];
		},
	},
	methods: {
		onCli() {},
		onFocus() {
			this.dateShown = true;
			document.body.addEventListener("click", this.onClick);
		},
		onClick(e) {
			let trgt = e.target;
			if (
				!(
					trgt.classList.contains("dateComponent") ||
					trgt.closest(".dateComponent")
				)
			) {
				this.dateShown = false;
				document.body.removeEventListener("click", this.onClick);
			}
		},
		onChange(e) {
			this.$emit("change", { name: this.propname, value: e });
		},
		onDpChange(e) {
			setTimeout(() => {
				this.dateShown = false;
			}, 300);
			this.value = `${Intl.DateTimeFormat(this.$i18n.locale, {
				day: "2-digit",
			}).format(new Date(e))}.${Intl.DateTimeFormat(this.$i18n.locale, {
				month: "numeric",
				minimumIntegerDigits: 3,
			}).format(new Date(e))}.${Intl.DateTimeFormat(this.$i18n.locale, {
				year: "numeric",
			}).format(new Date(e))}`;
		},
	},
	mounted() {
		if (this.prevalue) {
			this.value = `${Intl.DateTimeFormat(this.$i18n.locale, {
				day: "2-digit",
			}).format(this.prevalue)}.${Intl.DateTimeFormat(this.$i18n.locale, {
				month: "numeric",
				minimumIntegerDigits: 3,
			}).format(this.prevalue)}.${Intl.DateTimeFormat(this.$i18n.locale, {
				year: "numeric",
			}).format(this.prevalue)}`;
		}
	},
};
</script>

<style lang="scss" scoped>
.dateComponent {
	margin: 16px 0;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	position: relative;
	box-shadow: 0 0 0 1px inset #8e8e93;
	border-radius: 3px;
	.date {
		font-style: normal;
		font-weight: 400;
		// font-size: 18px;
		line-height: 130%;
		letter-spacing: -0.03em;
		// color: #aeaeb2;
		cursor: pointer;
		outline: none;
		border: none;
	}
	.date::placeholder {
		color: #aeaeb2;
	}
	.ic-calendar {
		cursor: pointer;
		font-size: 20px;
		color: gray;
	}
	.date_select {
		position: absolute;
		top: calc(100% + 2px);
		z-index: 100;
		left: 0;
	}
	.tip {
		color: red;
		margin-top: 3px;
	}
}
</style>